<template>
  <!-- TODO: small version -->
  <!-- TODO: client-side pagination sort -->
  <v-layout column class="overflow-y-hidden" style="height: 100%;">
    <template v-if="list.length && !(loading || tableLoading)">
      <desktop-list
        :prependActions="prependActions"
        :appendActions="appendActions"
        :displayHeaders="displayHeaders"
        :headers="headers"
        :list="list"
        @changeSort="path => changeSort(path)"
        :hidePagination="externalPagination"
        :pagination="pagination"
        :serverside="serverside"
        :prependHeader="prependHeader"
        :customStyle="customStyle"
        @dragReorder="dragReorder"
        :loading="loading || tableLoading"
        :itemsPerPageOptions="itemsPerPageOptions"
        :options="options"
        :footerProps="footerProps"
        :serversideSorting="serversideSorting"
        @appendAction="(action, item) => $emit(action, item)"
      >
        <template v-slot:itemFields="{ item }">
          <td v-for="(key, index) in displayHeaders" :key="index" :class="key.class" :style="key.style">
            <slot :name="`${key.path}`" :item="item">
              <template v-if="key.editable">
                <editable-field :resetLoading="resetLoading" @edited="(newVal) => propagateFieldEdition(key.editEvent, item, newVal)" :value="getObjectValue(item, key)" />
              </template>
              <template v-else-if="key.isFullDate">
                {{ getObjectValue(item, key) ? dayjs(getObjectValue(item, key)).format('DD/MM/YYYY HH:mm') : null }}
              </template>
              <template v-else-if="key.willHaveValue">
                <loading-field
                  :value="getObjectValue(item, key)"
                  :willHaveValue="key.willHaveValue(item)"
                />
              </template>
              <template v-else>
                {{ getObjectValue(item, key) }}
              </template>
            </slot>
          </td>
        </template>
        <template v-slot:appendActions="{ item }">
          <slot name="appendActions" :item="item" />
        </template>
      </desktop-list>
      <pagination
        v-if="externalPagination && list.length && !loading"
        :external="serverside"
        :table="listId"
      />
    </template>
    <template v-else>
      <v-layout justify-center class="mt-5">
        <v-progress-circular v-if="loading || tableLoading" indeterminate color="primary darken-1"/>
        <span v-else class="grey--text">{{ $t('pagination.no_data') }}</span>
      </v-layout>
    </template>
    <confirm-modal
      :triggerOpen="confirmModal.triggerOpen"
      :text="confirmModal.text"
      :title="confirmModal.title"
      @cancel="resetConfirmModal()"
      @accept="acceptConfirmModal()"
    />
  </v-layout>
</template>
<script>
import permissionMixins from '@/mixins/Permission'
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'ItemList',
  props: {
    // items to poblate the table with
    list: {
      type: Array,
      required: true
    },
    // list identifier to save config
    listId: {
      type: String,
      required: true
    },
    // search terms used on the filter function (refer to Search mixin)
    search: [Object, String],
    // selectable number of items per page
    itemsPerPageOptions: Array,
    itemsPerPage: Number,
    // loading status
    loading: Boolean,
    // list of every displayable header. should follow following format:
    // {
    //    text: String, action name shown on hover
    //    Value properties (priority: getValue -> paths -> path)
      //    getValue: Function, returns value to display in field
      //    paths: Array, shows value of first path with value
      //    path: String, path to the displayed value
    //    show: Boolean, indicates if the column is shown by default
    //    class: String, classes to apply to the column
    //    style: String, style to apply to the column
    //    editable: Boolean, indicates an editable text field
    //    editEvent: String, event emitted when field is saved
    //    sortable: Boolean, indicates wheter the column shows sortable conduct. true by default
    //    permission: String, indicates whether a column should turn disabled when permission is not active. Uses column decoration. May require custom translation
    //    willHaveValue: Function, indicates if loading field should be shown. Takes item and returns Boolean
    // }
    availableHeaders: {
      type: Array,
      required: true
    },
    // one of the available search filters from the Search mixin
    searchFilter: {
      type: Function
    },
    // actions that display a state for each item, situated at the left. should follow the following array format:
    // {
    //    show: Boolean, indicates if the action is to be shown
    //    icon: String, icon that represents the action
    //    action: String, name of the event emitted on click
    //    getText: Function, action name shown on hover. Defaults to 'text' if absent
    //    text: String, action name shown on hover
    //    activeClass: String, class to apply when activeValue is met
    //    confirmation: { title: String, text: String } Opens a confirmation modal before emiting an event
    //    activeValue: { path: 'message.name', value: 'deactivated' }, indicates when active class should be applied (item[path] === value)
    //    disabled: Boolean
    //    tooltipText: String
    //    unavailable: { path: 'editable', value: false, alternate: { icon: '', text: '' }, function: function(), disable: true, disableFunction: function() } Indicates when an item must hide or replace an action. Function indicates if item should be hidden, which replaces path and value
    // }
    prependActions: Array,
    // actions shown on hover for each item, situated at the right. should follow the following array format:
    // {
    //    show: Boolean or Function, indicates if the action is to be shown. Takes item as prop for function
    //    loading: Boolean or Function, indicates if the action is loading. Takes item as prop for function
    //    icon: String, icon that represents the action
    //    action: String, name of the event emitted on click
    //    getText: Function, action name shown on hover. Defaults to 'text' if absent
    //    text: String, action name shown on hover
    //    confirmation: { title: String, text: String } Opens a confirmation modal before emiting an event
    //    unavailable: { path: 'editable', value: false, alternate: { icon: '', text: '' }, function: function(), disable: true, disableFunction: function() } Indicates when an item must hide or replace an action. Function indicates if item should be hidden, which replaces path and value
    // }
    appendActions: Array,
    // removes the automatic loading state of an editable field or action on change
    resetLoading: Date,
    // set to true to hide this item's pagination
    externalPagination: Boolean,
    // set to true if the items are provided by a paginated request
    serverside: Boolean,
    refreshColumnDecoration: Date,
    decorateColumnAction: [String, Function],
    customStyle: Object,
    prependHeader: Boolean // Ensures prependActions header
  },
  data () {
    return {
      dayjs,
      pagination: {
        itemsPerPageOptions: [
          10,
          25,
          50,
          {
            text: this.$t('pagination.items_per_page_all'),
            value: -1
          }
        ]
      },
      serversideSorting: {},
      headers: [],
      displayHeaders: [],
      mouse: {
        pressed: false,
        startX: null,
        target: null
      },
      confirmModal: {
        triggerOpen: null,
        text: null,
        title: null,
        item: null,
        action: null
      }
    }
  },
  computed: {
    ...mapState({
      paginationState: 'pagination'
    }),
    tablePagination () {
      return this.paginationState.tables[this.listId]
    },
    tableLoading () {
      return this.tablePagination ? this.tablePagination.loading : false
    },
    options () {
      const pages = this.tablePagination ? this.tablePagination.pages : null
      return {
        page: pages ? pages.page : 0,
        itemsPerPage: pages ? pages.itemsPerPage : 10
      }
    },
    footerProps () {
      return {
        pagination: this.pagination,
        itemsPerPageText: this.$t('pagination.items_per_page'),
        itemsPerPageOptions: this.computedItemsPerPageOptions
      }
    },
    computedItemsPerPageOptions () {
      return this.itemsPerPageOptions ? this.itemsPerPageOptions : this.pagination.itemsPerPageOptions
    }
  },
  methods: {
    openConfirmModal (action, item) {
      this.confirmModal.text = action.confirmation.text
      this.confirmModal.title = action.confirmation.title
      this.confirmModal.item = item
      this.confirmModal.action = action
      this.confirmModal.triggerOpen = new Date()
    },
    acceptConfirmModal () {
      this.$emit(this.confirmModal.action.action, this.confirmModal.item)
      this.resetConfirmModal()
    },
    resetConfirmModal () {
      this.confirmModal.text = null
      this.confirmModal.title = null
      this.confirmModal.item = null
    },
    propagateFieldEdition (event, item, newValue) {
      this.$emit(event, item, newValue)
    },
    changeSort (column) {
      if (this.serverside) {
        if (this.serversideSorting.sortBy === column && !this.serversideSorting.descending) {
          this.serversideSorting.descending = true
        } else if (this.serversideSorting.sortBy === column) {
          this.serversideSorting.sortBy = null
          this.serversideSorting.descending = null
        } else {
          this.serversideSorting.sortBy = column
          this.serversideSorting.descending = false
        }
        this.$store.commit('pagination/set', { newPag: this.serversideSorting, table: this.listId })
      } else {
        if (this.pagination.sortBy === column && !this.pagination.descending) {
          this.pagination.descending = true
        } else if (this.pagination.sortBy === column) {
          this.pagination.sortBy = null
          this.pagination.descending = null
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      }
    },
    getTouchEvent (event) {
      var touch = null
      if (event.touches && event.touches.length) {
        for (var i = 0; i < event.touches.length; i++) {
          var t = event.touches[i]
          if (t && t.target.tagName === 'TH' && !t.target.classList.contains('no-resizable')) {
            touch = t
          }
        }
        return touch
      } else {
        return event
      }
    },
    mousePressed (event) {
      event = event || window.event
      event = this.getTouchEvent(event)
      if (event) {
        this.mouse.target = event.target || event.srcElement
        if (this.mouse.target.tagName === 'TH' && !this.mouse.target.classList.contains('no-resizable') && this.$vuetify.breakpoint.smAndUp) {
          this.mouse.pressed = true
          this.mouse.startX = event.pageX
          this.mouse.handle = this.mouse.target
          this.mouse.startWidth = this.mouse.target.offsetWidth
          // this.$refs.table.$el.classList.add('resizing') FIXME
        }
      }
    },
    mouseUp () {
      if (this.mouse.pressed && this.$vuetify.breakpoint.smAndUp) {
        // this.$refs.table.$el.classList.remove('resizing') FIXME
        this.mouse.pressed = false
      }
    },
    mouseMove (event) {
      var touchEvent = event
      event = this.getTouchEvent(event)
      if (touchEvent.touches && touchEvent.touches.length && !this.mouse.pressed) {
        this.mousePressed(event)
      }
      if (this.mouse.pressed && this.$vuetify.breakpoint.smAndUp) {
        event = event || window.event
        this.mouse.target.style.width = (this.mouse.startWidth + (event.pageX - this.mouse.startX)) + 'px'
        this.mouse.target.style.minWidth = (this.mouse.startWidth + (event.pageX - this.mouse.startX)) + 'px'
      }
    },
    getObjectValue (object, key) {
      if (key.getValue) {
        return key.getValue(object)
      }
      else if (key.paths) {
        const firstPathWithValue = key.paths.find(path => this.getObjectValue(object, { path }))
        return firstPathWithValue ? this.getObjectValue(object, { path: firstPathWithValue }) : null
      }
      else {
        var paths = key.path.split('.')
        var current = object
        var i
  
        for (i = 0; i < paths.length; ++i) {
          if (current[paths[i]] === undefined || current[paths[i]] === null) {
            return null
          } else {
            current = current[paths[i]]
          }
        }
        return current
      }
    },
    dragReorder ({ oldIndex, newIndex }) {
      const movedItem = this.headers.splice(oldIndex, 1)[0]
      this.headers.splice(newIndex, 0, movedItem)
      this.headers = [...this.headers]
    }
  },
  watch: {
    refreshColumnDecoration: {
      handler: function () {
        this.displayHeaders.forEach(header => {
          if (header.show) {
            if (typeof this.decorateColumnAction === 'string') {
              this.$store.dispatch(this.decorateColumnAction, header.path)
            } else {
              this.decorateColumnAction(header.path)
            }
          }
        })
      }
    },
    availableHeaders: {
      handler: function (val) {
        this.headers = this.availableHeaders
      },
      deep: true
    },
    headers: {
      handler: function (val) {
        this.displayHeaders = val.filter((header) => header.show && !header.disabled)
      },
      deep: true
    },
    displayHeaders (newVal, oldVal) {
      if (newVal.length > oldVal.length && (oldVal.length || this.refreshColumnDecoration)) {
        newVal.forEach(val => {
          if (oldVal.findIndex((o) => o === val) < 0) {
            if (this.decorateColumnAction) {
              if (typeof this.decorateColumnAction === 'string') {
                this.$store.dispatch(this.decorateColumnAction, val.path)
              } else {
                this.decorateColumnAction(val.path)
              }
            }
            this.$emit('newColumn', val.path)
          }
        })
      }
      localStorage.setItem(`listConfig:${this.listId}`, JSON.stringify(newVal.filter((h) => h.show).map((h) => h.path)))
    }
  },
  created () {
    var savedDisplayHeaders = (localStorage.getItem(`listConfig:${this.listId}`) ? JSON.parse(localStorage.getItem(`listConfig:${this.listId}`)) : [])
      .filter(path => this.availableHeaders.find(v => v.path === path))
    if (savedDisplayHeaders.length) {
      this.availableHeaders.sort(function (a, b) {
        var aIndex = savedDisplayHeaders.findIndex((h) => { return h === a.path })
        var bIndex = savedDisplayHeaders.findIndex((h) => { return h === b.path })
        if (aIndex < 0 && bIndex >= 0) {
          return 1
        }
        if (bIndex < 0 && aIndex >= 0) {
          return -1
        }
        if (aIndex >= 0 && bIndex >= 0) {
          if (aIndex > bIndex) {
            return 1
          }
          if (aIndex < bIndex) {
            return -1
          }
        }
        return 0
      })
    }
    if (this.tablePagination && this.tablePagination.pages.sortBy) {
      const o = this.serverside ? this.serversideSorting : this.pagination
      o.sortBy = this.tablePagination.pages.sortBy
    }
    this.headers = this.availableHeaders.map((h) => {
      const disabled = h.permission ? !this.isPermissionActive(h.permission) : false
      h.show = (savedDisplayHeaders.length ? savedDisplayHeaders.includes(h.path) : h.show) && !disabled
      h.disabled = disabled || h.disabled
      return h
    })
    this.displayHeaders = this.availableHeaders.filter((header) => header.show)
    document.addEventListener('mousedown', this.mousePressed)
    document.addEventListener('mouseup', this.mouseUp)
    document.addEventListener('mousemove', this.mouseMove)
    document.addEventListener('touchmove', this.mouseMove)
  },
  components: {
    EditableField: () => import('@/components/app-components/EditableField.vue'),
    Pagination: () => import('@/components/app-components/listing/Pagination.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    DesktopList: () => import('@/components/app-components/listing/DesktopList.vue'),
    LoadingField: () => import('@/components/app-components/LoadingField.vue')
  },
  mixins: [
    permissionMixins
  ],
  beforeDestroy () {
    document.removeEventListener('mousedown', this.mousePressed)
    document.removeEventListener('mouseup', this.mouseUp)
    document.removeEventListener('mousemove', this.mouseMove)
    document.removeEventListener('touchstart', this.mousePressed)
    document.removeEventListener('touchend', this.mouseUp)
    document.addEventListener('touchmove', this.mouseMove)
    if (this.paginationState.tables[this.listId]) {
      this.$store.commit('pagination/reset', { table: this.listId })
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content.theme--light.menuable__content__active {
  z-index: 300 !important;
}
.height-auto {
  height: auto !important;
}
</style>
